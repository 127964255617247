import React, { useState, useEffect } from "react";
import { Modal, Button, Tooltip } from "antd";
import { Product, Variant } from "../models/Product";
import styles from "../assets/css/VariantModal.module.scss";

interface ModalHeaderProps {
  selectedTag: string;
  sizeTags?: string[];
  onTagClick: (tag: string) => void;
}

function ModalHeader({ selectedTag, sizeTags, onTagClick }: ModalHeaderProps) {
  return (
    <>
      <div className={styles.modalTitle}>This product has following styles</div>
      {sizeTags && sizeTags.length > 1 && (
        <div>
          {sizeTags.map((tag, index) => (
            <Button
              key={`${tag}-${index}`}
              className={styles.sizeBtn}
              type={selectedTag === tag ? "primary" : "default"}
              onClick={() => {
                onTagClick(tag);
              }}
            >
              {tag}
            </Button>
          ))}
        </div>
      )}
    </>
  );
}

interface VariantModalProps {
  product?: Product;
  onCancel: () => void;
  onChooseBalloon: (tem: Product | Variant) => void;
}

function VariantModal({ product, onCancel, onChooseBalloon }: VariantModalProps) {
  const [selectedTag, setSeletedTag] = useState("");
  const sizeTags = product?.tags.filter(tag => tag.includes("''"));
  const productVariants = product?.variants.edges;

  // every time a product with variants and different size is clicked, set selected tag to be the first one of its own
  useEffect(() => {
    if (sizeTags && productVariants) {
      setSeletedTag(sizeTags[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const onTagClick = (tag: string) => {
    setSeletedTag(tag);
  };

  const filteredVariants =
    sizeTags && sizeTags.length > 1
      ? productVariants?.filter(variant => variant.node.title.includes(selectedTag))
      : productVariants;

  const getToolTipText = (variant: Variant) => {
    return `Price: $${variant.node.priceV2.amount}`;
  };

  return (
    <Modal
      title={
        <ModalHeader
          selectedTag={selectedTag}
          sizeTags={sizeTags}
          onTagClick={onTagClick}
        />
      }
      visible={!!product}
      onCancel={onCancel}
      footer={null}
    >
      {filteredVariants && (
        <div className={styles.imagesWrapper}>
          {filteredVariants.map((variant, index) => (
            <Tooltip
              key={`${variant.node.id}-${index}`}
              title={getToolTipText(variant)}
            >
              <img
                src={variant.node.image.transformedSrc}
                onClick={() => {
                  // eslint-disable-next-line no-param-reassign
                  variant.node.productType = product?.productType;
                  onChooseBalloon(variant);
                  onCancel();
                }}
              />
            </Tooltip>
          ))}
        </div>
      )}
    </Modal>
  );
}

export default VariantModal;
