/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Structure } from "../models/Structure";
import styles from "../assets/css/StructureSelection.module.scss";
import standard from "../assets/images/structures/standard.png";
import trio from "../assets/images/structures/trio.png";
import cascading from "../assets/images/structures/cascading.png";

interface StructureSelectionProps {
  selectedStructure: Structure;
  setSelectedStructure: React.Dispatch<React.SetStateAction<Structure>>;
}

function StructureSelection({
  selectedStructure,
  setSelectedStructure,
}: StructureSelectionProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Balloon Bouquet</div>
      <div className={styles.sectionTitle}>Balloon Structure</div>
      <div className={styles.imagesSection}>
        <div
          className={`${styles.imageWrapper} ${
            selectedStructure === "standard" ? styles.selected : styles.unselected
          }`}
        >
          <img
            className={styles.image}
            onClick={() => {
              setSelectedStructure("standard");
            }}
            src={standard}
          />
          <div className={styles.name}>Standard</div>
        </div>
        <div
          className={`${styles.imageWrapper} ${
            selectedStructure === "trio" ? styles.selected : styles.unselected
          }`}
        >
          <img
            className={styles.image}
            onClick={() => {
              setSelectedStructure("trio");
            }}
            src={trio}
          />
          <div className={styles.name}>Standard Trio</div>
        </div>
        <div
          className={`${styles.imageWrapper} ${
            selectedStructure === "cascading" ? styles.selected : styles.unselected
          }`}
        >
          <img
            className={styles.image}
            onClick={() => {
              setSelectedStructure("cascading");
            }}
            src={cascading}
          />
          <div className={styles.name}>Cascading</div>
        </div>
      </div>
    </div>
  );
}

export default StructureSelection;
