/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import PurchasedItem from "../components/PurchasedItem";
import { Product, Variant } from "../models/Product";
import styles from "../assets/css/CheckoutView.module.scss";

interface CheckoutViewProps {
  skeleton: (Product | Variant | null)[][];
  total: string;
}

function CheckoutView({ skeleton, total }: CheckoutViewProps) {
  const [items, setItems] = useState<[Product | Variant, number][]>([]);

  useEffect(() => {
    const temp: Map<string, [Product | Variant, number]> = new Map();
    for (const row of skeleton) {
      for (const item of row) {
        if (item) {
          const id = "images" in item ? item.id : item.node.id;
          const curr = temp.get(id) || [item, 0];
          curr[1] += 1;
          temp.set(id, curr);
        }
      }
    }
    setItems(Array.from(temp.values()));
  }, [skeleton]);

  return (
    <div className={styles.wrapper}>
      {items.length
        ? items.map(([item, amount]) => (
            <PurchasedItem
              key={"images" in item ? item.id : item.node.id}
              item={item}
              amount={amount}
            />
          ))
        : "No items selected"}
      <div className={styles.subTotal}>
        Subtotal: ${total}CAD, before taxes and service fee
      </div>
    </div>
  );
}

export default CheckoutView;
