import React from "react";
import { Product, Variant } from "../models/Product";
import styles from "../assets/css/PurchasedItem.module.scss";

interface PurchasedItemProps {
  item: Product | Variant;
  amount: number;
}

function PurchasedItem({ item, amount }: PurchasedItemProps) {
  const findSizeFromTags = (tags: string[]) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const tag of tags) {
      if (tag.includes("''")) {
        return tag;
      }
    }
    return "N/A";
  };

  return (
    <div className={styles.itemWrapper}>
      <div>
        <img
          className={styles.itemImage}
          src={
            "images" in item
              ? item.images.edges[0].node.transformedSrc
              : item.node.image.transformedSrc
          }
        />
        <div style={{ display: "inline-block", marginLeft: 3 }}>
          <div>{"images" in item ? item.title : item.node.title}</div>
          {"images" in item && <div>Size: {findSizeFromTags(item.tags)}</div>}
        </div>
      </div>
      <div>* {amount}</div>
    </div>
  );
}

export default PurchasedItem;
