import React from "react";
import BouquetCard from "../components/BouquetCard";
import homeBouquet from "../assets/images/landing/home-bouquet.jpg";
import homeColumn from "../assets/images/landing/home-column.jpg";
import homeArch from "../assets/images/landing/home-arch.jpg";
import shopWindow from "../assets/images/landing/shop-window.jpg";
import styles from "../assets/css/BouquetBottomSection.module.scss";

// In landing
function BouquetBottomSection() {
  return (
    <div className={styles.bottomSection}>
      <div className={styles.row}>
        <BouquetCard
          title="Balloon Bouquet"
          buttonText="Build Bouquets"
          img={homeBouquet}
        />
        <BouquetCard
          title="Balloon Column"
          buttonText="Build Column"
          img={homeColumn}
          secondChild
        />
      </div>
      <div className={styles.row}>
        <BouquetCard title="Balloon Arch" buttonText="Build Arch" img={homeArch} />
        <BouquetCard
          title="Not Satisfied?"
          subTitle="Try out our custom inquiry!"
          buttonText="Custom Inquiry"
          img={shopWindow}
          secondChild
        />
      </div>
    </div>
  );
}

export default BouquetBottomSection;
