import React from "react";
import BalloonSkeleton from "./BalloonSkeleton";
import { Structure } from "../models/Structure";
import { Product, Variant } from "../models/Product";

interface SelectedStructureDisplayProps {
  selectedStructure: Structure;
  skeleton: (Product | Variant | null)[][];
  onDeleteProduct: (row: number, col: number) => void;
  onClickBalloonInDisplay: (row: number, col: number) => void;
  selectedBalloons: [number, number][];
  deleteSelectedBalloons: () => void;
}

function SelectedStructureDisplay({
  selectedStructure,
  skeleton,
  onDeleteProduct,
  onClickBalloonInDisplay,
  selectedBalloons,
  deleteSelectedBalloons,
}: SelectedStructureDisplayProps) {
  return (
    <BalloonSkeleton
      structure={selectedStructure}
      deleteSelectedBalloons={deleteSelectedBalloons}
      onDeleteProduct={onDeleteProduct}
      skeleton={skeleton}
      onClickBalloonInDisplay={onClickBalloonInDisplay}
      selectedBalloons={selectedBalloons}
    />
  );
}

export default SelectedStructureDisplay;
