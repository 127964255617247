import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/css/BouquetCard.module.scss";

interface BouquetCardProps {
  title: string;
  subTitle?: string;
  buttonText: string;
  img: string;
  secondChild?: boolean;
}

function BouquetCard({
  title,
  subTitle,
  buttonText,
  img,
  secondChild = false,
}: BouquetCardProps) {
  return (
    <div
      style={{ marginLeft: secondChild ? "24px" : 0 }}
      className={styles.bouquetCard}
    >
      <div className={styles.left}>
        <div className={styles.top}>
          <div>{title}</div>
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        </div>
        <Link to="/bouquet-builder">
          <div style={{ color: "#fff" }} className={styles.bottom}>
            {buttonText}
          </div>
        </Link>
      </div>
      <div className={styles.right}>
        <img src={img} />
      </div>
    </div>
  );
}

export default BouquetCard;
