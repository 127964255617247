/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useMemo } from "react";
import StructureSelection from "../views/StructureSelection";
import TotalPrice from "../components/TotalPrice";
import StageToggler from "../components/StageToggler";
import { Structure } from "../models/Structure";
import BouquetHeader from "../components/BouquetHeader";
import SelectedStructureDisplay from "../components/SelectedStructureDisplay";
import BalloonNumberSlider from "../components/BalloonNumberSlider";
import TopBalloonSelection from "../views/TopBalloonSelection";
import StageTracker from "../components/StageTracker";
import Footer from "../components/Footer";
import styles from "../assets/css/BuildingProcess.module.scss";
import { Product, Variant } from "../models/Product";
import BottomBalloonsSelection from "../views/BottomBalloonsSelection";
import CheckoutView from "../views/CheckoutView";

const STEPS = [
  "Choose your balloon structure",
  "Choose your top balloon",
  "Choose your bottom balloons",
  "Confirm your order",
];

interface BuildingProcssProps {
  isLoading: boolean;
  products: Product[];
}

// First stage of bouquet builder
function BuildingProcess({ isLoading, products }: BuildingProcssProps) {
  const [currentStage, setCurrentStage] = useState<number>(0);
  // stage 1:
  const [selectedStructure, setSelectedStructure] = useState<Structure>("standard");
  // balloon graph built on the left part
  const [skeleton, setSkeleton] = useState<(Product | Variant | null)[][]>([]);
  // number of levels under top balloon:
  const [bouquetLevels, setBouquetLevels] = useState<number>(1);
  // allows users to select multiple ones and delete them at once
  const [selectedBalloons, setSelectedBalloons] = useState<[number, number][]>([]);

  // click on a image on the structure display
  const onClickBalloonInDisplay = (row: number, col: number) => {
    // top balloon can not be modified by clicking, bottom balloons can not be selected when choosing top balloon
    if ((row === 0 && col === 0) || currentStage === 1) {
      return;
    }
    const selectedIndex = selectedBalloons.findIndex(
      ([a, b]) => a === row && b === col
    );
    if (selectedIndex === -1) {
      setSelectedBalloons([...selectedBalloons, [row, col]]);
    } else {
      setSelectedBalloons(
        selectedBalloons.filter((b, idx) => idx !== selectedIndex)
      );
    }
  };

  const onDeleteProduct = (row: number, col: number) => {
    const skeletonCopy = [...skeleton];
    skeletonCopy[row][col] = null;
    setSkeleton(skeletonCopy);
  };

  const replaceDummiesWithBalloons = (item: Product | Variant) => {
    const skeletonCopy = [...skeleton];
    for (const [row, col] of selectedBalloons) {
      skeletonCopy[row][col] = item;
    }

    setSkeleton(skeletonCopy);
    setSelectedBalloons([]);
  };

  const deleteSelectedBalloons = () => {
    const skeletonCopy = [...skeleton];
    for (const [row, col] of selectedBalloons) {
      skeletonCopy[row][col] = null;
    }

    setSkeleton(skeletonCopy);
    setSelectedBalloons([]);
  };

  const onChooseBalloon = (item: Product | Variant) => {
    const skeletonCopy = [...skeleton];
    skeletonCopy[0][0] = item;
    setSkeleton(skeletonCopy);
  };

  const getTotalPrice = () => {
    let total = 0.0;
    const rows = skeleton.length;
    for (let i = 0; i < rows; i++) {
      const row = skeleton[i];
      for (const item of row) {
        if (item) {
          if ("images" in item) {
            total += parseFloat(item.priceRange.minVariantPrice.amount);
          } else {
            total += parseFloat(item.node.priceV2.amount);
          }
        }
      }
    }
    return total;
  };

  const totalPrice = useMemo(() => {
    const price = getTotalPrice();
    return parseFloat(price.toString()).toFixed(2);
  }, [skeleton]);

  const getDummyLevel = () => {
    let level = [];
    if (selectedStructure === "trio") {
      level = [null, null, null];
    } else if (selectedStructure === "standard") {
      level = [null, null];
    } else {
      level = [null];
    }
    return level;
  };

  useEffect(() => {
    let currLevel = skeleton.length - 1;
    const skeletonCopy = [...skeleton];
    while (currLevel !== bouquetLevels) {
      if (currLevel < bouquetLevels) {
        skeletonCopy.push(getDummyLevel());
        currLevel++;
      } else {
        skeletonCopy.pop();
        currLevel--;
      }
    }
    setSkeleton(skeletonCopy);
  }, [bouquetLevels]);

  useEffect(() => {
    const currSkeleton = [[null]];
    for (let i = 0; i < bouquetLevels; i++) {
      currSkeleton.push(getDummyLevel());
    }
    setSkeleton(currSkeleton);

    setSelectedBalloons([]);
  }, [selectedStructure]);

  const maxBouquetLevels = useMemo(() => {
    if (selectedStructure === "trio") {
      return 5;
    }
    return 6;
  }, [selectedStructure]);

  const balloonNumber = useMemo(() => {
    if (selectedStructure === "standard") {
      return 1 + bouquetLevels * 2;
    }
    if (selectedStructure === "trio") {
      return 1 + bouquetLevels * 3;
    }
    return 1 + bouquetLevels;
  }, [selectedStructure, bouquetLevels]);

  const previous = () => {
    setCurrentStage(currentStage - 1);
  };

  const next = () => {
    setCurrentStage(currentStage + 1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <BouquetHeader />
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <SelectedStructureDisplay
            selectedStructure={selectedStructure}
            onDeleteProduct={onDeleteProduct}
            skeleton={skeleton}
            deleteSelectedBalloons={deleteSelectedBalloons}
            onClickBalloonInDisplay={onClickBalloonInDisplay}
            selectedBalloons={selectedBalloons}
          />
          {currentStage === 4 && <TotalPrice totalPrice={totalPrice} />}
        </div>
        <div className={styles.middle}>
          <section className={currentStage === 0 ? "" : styles.hidden}>
            <StructureSelection
              selectedStructure={selectedStructure}
              setSelectedStructure={setSelectedStructure}
            />
            <BalloonNumberSlider
              balloonNumber={balloonNumber}
              maxBouquetLevels={maxBouquetLevels}
              setBouquetLevels={setBouquetLevels}
            />
          </section>
          <section className={currentStage === 1 ? "" : styles.hidden}>
            <TopBalloonSelection
              products={products}
              onChooseBalloon={onChooseBalloon}
            />
          </section>
          <section className={currentStage === 2 ? "" : styles.hidden}>
            <BottomBalloonsSelection
              products={products}
              replaceDummiesWithBalloons={replaceDummiesWithBalloons}
            />
          </section>
          <section className={currentStage === 3 ? "" : styles.hidden}>
            <CheckoutView skeleton={skeleton} total={totalPrice} />
          </section>
          <StageToggler
            currentStage={currentStage}
            previous={previous}
            next={next}
            skeleton={skeleton}
            structure={selectedStructure}
          />
        </div>
        <div className={styles.right}>
          <StageTracker
            steps={STEPS}
            currentStage={currentStage}
            setCurrentStage={setCurrentStage}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BuildingProcess;
