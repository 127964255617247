import React from "react";
import BouquetLandingImage from "../components/BouquetLandingImage";
import BouquetHeader from "../components/BouquetHeader";
import Footer from "../components/Footer";
import landing from "../assets/images/landing/home-banner.jpg";
import BouquetBottomSection from "../views/BouquetBottomSection";
import styles from "../assets/css/BouquetBuilder.module.scss";

// Landing page
function BouquetBuilder() {
  return (
    <>
      <BouquetHeader />
      <div className={styles.container}>
        <BouquetLandingImage imgSrc={landing} />
        <BouquetBottomSection />
      </div>
      <Footer />
    </>
  );
}

export default BouquetBuilder;
