/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useMemo } from "react";
import styles from "../assets/css/StageToggler.module.scss";
import { Product, Variant } from "../models/Product";
import { Structure } from "../models/Structure";
import { checkout } from "../utils/apiClients";

interface StageTogglerProps {
  currentStage: number;
  previous: () => void;
  next: () => void;
  skeleton: (Product | Variant | null)[][];
  structure: Structure;
}

function StageToggler({
  currentStage,
  previous,
  next,
  skeleton,
  structure,
}: StageTogglerProps) {
  const [items, setItems] = useState<[Product | Variant, number][]>([]);
  const [checkoutUrl, setCheckoutUrl] = useState<string | null>(null);

  // A bouquet is compelete when every slot of the 2D array is occupied by a balloon
  const bouquetIsComplete = useMemo(
    () => skeleton.every(row => row.every(e => e !== null)),
    [skeleton]
  );

  useEffect(() => {
    if (currentStage === 3) {
      const temp: Map<string, [Product | Variant, number]> = new Map();
      for (const row of skeleton) {
        for (const item of row) {
          if (item) {
            const id = "images" in item ? item.id : item.node.id;
            const curr = temp.get(id) || [item, 0];
            curr[1] += 1;
            temp.set(id, curr);
          }
        }
      }
      setItems(Array.from(temp.values()));
    }
  }, [skeleton, currentStage]);

  useEffect(() => {
    // set checkout url to be null when items changes so that the url is always up-to-date
    async function getCheckoutUrl() {
      setCheckoutUrl(null);
      const url = await checkout(items, structure, skeleton);
      setCheckoutUrl(url);
    }
    if (bouquetIsComplete) {
      getCheckoutUrl();
    }
  }, [items, structure, bouquetIsComplete, skeleton]);

  return (
    <div className={styles.stageToggler}>
      <div
        style={{ visibility: currentStage === 0 ? "hidden" : "visible" }}
        className={styles.prev}
        onClick={previous}
      >
        Previous
      </div>
      {currentStage < 3 ? (
        <div className={styles.next} onClick={next}>
          Continue
        </div>
      ) : !bouquetIsComplete ? (
        <div className={styles.next}>
          Please complete your bouquet before checkout.
        </div>
      ) : (
        <div
          className={styles.next}
          onClick={() => {
            if (checkoutUrl) {
              window.open(checkoutUrl);
            }
          }}
        >
          {checkoutUrl ? "Checkout" : "Loading"}
        </div>
      )}
    </div>
  );
}

export default StageToggler;
