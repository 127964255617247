import React, { useState } from "react";
import styles from "../assets/css/BottomBalloonsSelection.module.scss";
import BalloonTypeSection from "../components/BalloonTypeSection";
import { Product, ProductType, Variant } from "../models/Product";

interface BottomBalloonsSelectionProps {
  products: Product[];
  replaceDummiesWithBalloons: (item: Product | Variant) => void;
}

const bottomBalloonTypes: ProductType[] = ["Mylar Foil Balloons", "Latex Balloons"];

function BottomBalloonsSelection({
  products,
  replaceDummiesWithBalloons,
}: BottomBalloonsSelectionProps) {
  // determines which section is opened
  const [sectionIndex, setSectionIndex] = useState<number | null>(null);

  const onSectionClick = (index: number) => {
    if (index === sectionIndex) {
      setSectionIndex(null);
    } else {
      setSectionIndex(index);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Balloon Bouquet</div>
      <div className={styles.sectionTitle}>Bottom Balloon Options</div>
      {bottomBalloonTypes.map((type, index) => (
        <BalloonTypeSection
          key={type}
          onSectionClick={() => {
            onSectionClick(index);
          }}
          typeName={type}
          selected={sectionIndex === index}
          products={products}
          onChooseBalloon={replaceDummiesWithBalloons}
        />
      ))}
    </div>
  );
}

export default BottomBalloonsSelection;
