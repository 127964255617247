import React from "react";
import { Slider } from "antd";
import styles from "../assets/css/BalloonNumberSlider.module.scss";

interface BalloonNumberSliderProps {
  balloonNumber: number;
  maxBouquetLevels: number;
  setBouquetLevels: React.Dispatch<React.SetStateAction<number>>;
}

function BalloonNumberSlider({
  balloonNumber,
  maxBouquetLevels,
  setBouquetLevels,
}: BalloonNumberSliderProps) {
  return (
    <div style={{ marginBottom: 64 }}>
      <div className={styles.subTitle}>Balloon Number</div>
      <div className={styles.balloonNumberSlider}>
        <Slider
          className={styles.slider}
          trackStyle={{ backgroundColor: "#daa520" }}
          handleStyle={{ borderColor: "#daa520" }}
          tooltipVisible={false}
          min={1}
          max={maxBouquetLevels}
          onChange={setBouquetLevels}
        />
        <span>{balloonNumber}</span>
      </div>
    </div>
  );
}

export default BalloonNumberSlider;
