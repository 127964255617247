/* eslint-disable no-nested-ternary */
import React from "react";
import { Tooltip } from "antd";
import dummy from "../assets/images/balloons/dummy_balloon.png";
import { Product, Variant } from "../models/Product";
import styles from "../assets/css/BalloonSkeleton.module.scss";
import { Structure } from "../models/Structure";

interface SkeletonRowProps {
  structure: Structure;
  row: (Product | Variant | null)[];
  level: number;
  onDeleteProduct: (row: number, col: number) => void;
  onClickBalloonInDisplay: (row: number, col: number) => void;
  selectedBalloons: [number, number][];
}

function SkeletonRow({
  structure,
  row,
  level,
  onDeleteProduct,
  onClickBalloonInDisplay,
  selectedBalloons,
}: SkeletonRowProps) {
  const getBalloonImageUrl = (item: Product | Variant | null) => {
    if (item) {
      if ("images" in item) {
        return item.images.edges[0].node.transformedSrc;
      }
      return item.node.image.transformedSrc;
    }
    return dummy;
  };

  // if a displayed balloon is a product, get the size from tag
  // if it is a variant, get the size from variant sname
  const getSize = (item: Product | Variant | null): number | null => {
    if (!item) {
      return null;
    }
    if ("images" in item) {
      if (item.productType === "Megaloons Balloons") {
        return 38;
      }
      const size = item.tags.find(tag => tag.includes("''"));
      return size ? parseInt(size.slice(0, 2), 10) : null;
    }
    if (item.node.productType === "Megaloons Balloons") {
      return 38;
    }
    const index = item.node.title.indexOf("''");
    if (index !== -1) {
      return parseInt(item.node.title.slice(index - 2, index), 10);
    }
    return null;
  };

  const getScale = (size: number | null) => {
    if (size === null) {
      return "scale(1)";
    }
    if (size === 11) {
      return "scale(0.8)";
    }
    const ratio = Math.min((size * 0.85) / 14, 2);
    return `scale(${ratio})`;
  };

  const getTransformDegree = (index: number) => {
    let res = "";
    if (level === 0) {
      return structure === "cascading" ? "rotate(20deg)" : "";
    }
    switch (structure) {
      case "standard":
        res = index === 0 ? "rotate(-20deg)" : "rotate(20deg)";
        break;
      case "trio":
        if (index === 1) {
          res = "";
        } else {
          res = index === 0 ? "rotate(-30deg)" : "rotate(30deg)";
        }
        break;
      case "cascading":
        res = level % 2 === 1 ? "rotate(-40deg)" : "rotate(40deg)";
        break;
      default:
        break;
    }
    return res;
  };

  const getBorderStyleBasedOnBalloonStatus = (
    currentRow: number,
    currentCol: number
  ) => {
    if (selectedBalloons.find(([a, b]) => a === currentRow && b === currentCol)) {
      return "2px solid #caa352";
    }
    return "";
  };

  return (
    <div className={styles.row}>
      {row.map((item, index) => (
        <Tooltip
          key={`${index}-${level}`}
          title={
            item &&
            level === 0 &&
            index === 0 && (
              <div
                onClick={() => {
                  onDeleteProduct(level, index);
                }}
                className={styles.deleteToolTip}
              >
                Delete
              </div>
            )
          }
        >
          <img
            className={level === 0 && index === 0 ? styles.topItem : styles.item}
            style={{
              "transform": `${getTransformDegree(index)} ${getScale(getSize(item))}`,
              "border": getBorderStyleBasedOnBalloonStatus(level, index),
              "zIndex": structure === "trio" && index === 1 ? 3 : 0,
            }}
            onClick={() => {
              onClickBalloonInDisplay(level, index);
            }}
            src={getBalloonImageUrl(item)}
          />
        </Tooltip>
      ))}
    </div>
  );
}

interface BalloonSkeletonProps {
  structure: Structure;
  skeleton: (Product | Variant | null)[][];
  onDeleteProduct: (row: number, col: number) => void;
  onClickBalloonInDisplay: (row: number, col: number) => void;
  selectedBalloons: [number, number][];
  deleteSelectedBalloons: () => void;
}

function BalloonSkeleton({
  structure,
  skeleton,
  onDeleteProduct,
  onClickBalloonInDisplay,
  selectedBalloons,
  deleteSelectedBalloons,
}: BalloonSkeletonProps) {
  return (
    <div>
      <div onClick={deleteSelectedBalloons} className={styles.deleteBtn}>
        Delete Selected
      </div>
      <div className={styles.skeleton}>
        {skeleton.map((row, index) => (
          <SkeletonRow
            structure={structure}
            onDeleteProduct={onDeleteProduct}
            row={row}
            level={index}
            key={`${index}-${row}-skeleton`}
            onClickBalloonInDisplay={onClickBalloonInDisplay}
            selectedBalloons={selectedBalloons}
          />
        ))}
      </div>
    </div>
  );
}

export default BalloonSkeleton;
