import React from "react";
import styles from "../assets/css/Footer.module.scss";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className="">
          <div style={{ marginBottom: 25 }}>Shop All</div>
          <ul>
            <li className="">
              <a href="https://vancouverballoons.com/collections/helium-tank">
                Helium Tank Rental
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/latex-balloons">
                11" and 16" Latex Balloons
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/3ft-round-latex-balloons">
                36" Latex Balloons
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/confetti-balloons">
                Confetti Balloons
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/foil-number-balloons">
                Foil Number Balloons
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/foil-letter-balloons">
                Foil Letter Balloons
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/mylar-foil-balloons">
                Mylar Foil Balloons
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/supershapes-megaloons">
                Supershape Balloons
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/bubble-balloons">
                Bubble Balloons
              </a>
            </li>
          </ul>
        </div>
        <div className="">
          <p style={{ marginBottom: 25 }}>Shop Themed Packages</p>
          <ul className="">
            <li className="">
              <a href="https://vancouverballoons.com/collections/birthday-balloons">
                Birthday Balloon Package
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/baby-balloons">
                Baby Balloon Package
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/grad-balloons">
                Graduation Balloon Package
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/organic-arch-decoration-packages">
                Organic Arch Package
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/collections/wedding-engament-balloon-package">
                Wedding&amp;Engagement Package
              </a>
            </li>
          </ul>
        </div>
        <div className="">
          <p style={{ marginBottom: 25 }}>FAQs</p>
          <ul className="">
            <li className="">
              <a href="https://vancouverballoons.com/pages/delivery-guideline">
                Delivery Guideline
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/pages/cancellation-refund-policy">
                Cancellation/Refund Policy
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/pages/sustainability">
                Sustainability
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/pages/bio-degradeable-latex-balloon-colour-size-chart">
                Latex Balloon Colour/Size Chart
              </a>
            </li>
            <li className="">
              <a href="https://vancouverballoons.com/pages/local-collaborations">
                VB X Local Business
              </a>
            </li>
          </ul>
        </div>
        <div>
          <p style={{ marginBottom: 25 }}>Contact Us</p>
          <p>Tel: 604-684-7555</p>
          <p>hello@vancouverballoons.com</p>
          <p>
            MON-FRI ----- 10:00 - 14:30
            <br />
            SAT ------------- 9:30 - 13:30
            <br />
            SUN ------------------ <strong>Closed</strong>
          </p>
          <p>
            <a
              style={{ borderBottom: "1px solid #333232" }}
              href="https://www.google.ca/maps/place/4770+Main+St,+Vancouver,+BC+V5V+3R7/@49.242493,-123.1032735,17z/data=!3m1!4b1!4m5!3m4!1s0x548674098d80a6a5:0x174264776387ebb9!8m2!3d49.2424895!4d-123.1010848?hl=en&amp;authuser=0"
            >
              4770 Main Street{" "}
            </a>
          </p>
          <p>
            <a
              style={{ borderBottom: "1px solid #333232" }}
              href="https://www.google.ca/maps/place/4770+Main+St,+Vancouver,+BC+V5V+3R7/@49.242493,-123.1032735,17z/data=!3m1!4b1!4m5!3m4!1s0x548674098d80a6a5:0x174264776387ebb9!8m2!3d49.2424895!4d-123.1010848?hl=en&amp;authuser=0"
            >
              Vancouver, BC V5V 3R7
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
