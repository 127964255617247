import React from "react";
import styles from "../assets/css/BouquetLandingImage.module.scss";

interface BouquetLandingImageProps {
  imgSrc: string;
}

function BouquetLandingImage({ imgSrc }: BouquetLandingImageProps) {
  return (
    <div className={styles.landingImageConatiner}>
      <img className={styles.landingImage} src={imgSrc} alt="" />
      <div className={styles.imgText}>
        <div className={styles.title}>Create Your</div>
        <div className={styles.title}>Own Ballon Decor</div>
        <div>Stay eco-friendly with our biodegradable latex balloons!</div>
      </div>
    </div>
  );
}

export default BouquetLandingImage;
