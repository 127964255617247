import React from "react";
import styles from "../assets/css/ShopExpansion.module.scss";

function ShopExpansion() {
  return (
    <div className={styles.wrapper}>
      <ul className={styles.topList}>
        <li className="site-nav__childlist-item">
          <a
            href="https://vancouverballoons.com/pages/shop-single-balloons"
            className="site-nav__link site-nav__child-link site-nav__child-link--parent"
          >
            <span className="site-nav__label" style={{ fontWeight: 700 }}>
              SHOP SINGLE BALLOONS
            </span>
          </a>

          <ul className={styles.unorderedList}>
            <li>
              <a
                href="https://vancouverballoons.com/collections/latex-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">11'' and 16'' Latex Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/3ft-round-latex-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">3ft Round Latex Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com//collections/confetti-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Confetti Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/mylar-foil-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Mylar Foil Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/foil-letter-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Foil Letter Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/foil-number-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Foil Number Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/supershapes-megaloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Supershape Foil Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/bubble-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Bubble Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/260q-twisting-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">260Q Twisting Balloons</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/uninflated-latex-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Uninflated Latex Balloons</span>
              </a>
            </li>
          </ul>
        </li>

        <li className="site-nav__childlist-item">
          <a
            href="https://vancouverballoons.com/pages/balloon-packages"
            className="site-nav__link site-nav__child-link site-nav__child-link--parent"
          >
            <span className="site-nav__label" style={{ fontWeight: 700 }}>
              BALLOON PACKAGES
            </span>
          </a>

          <ul className={styles.unorderedList}>
            <li>
              <a href="https://vancouverballoons.com/collections/birthday-balloons">
                <span>Birthday Themed Balloon Package</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/baby-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Baby Themed Balloon Package</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/organic-arch-decoration-packages"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Organic Arch Package</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/wedding-engament-balloon-package"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">
                  Wedding&amp;Engagement Balloon Package
                </span>
              </a>
            </li>

            <li>
              <a
                href="/collections/grad-balloons"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">
                  Graduation Themed Balloon Package
                </span>
              </a>
            </li>

            <li>
              <a
                href="/collections/general-balloon-package"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">General Balloon Pacakge</span>
              </a>
            </li>
          </ul>
        </li>

        <li className="site-nav__childlist-item">
          <a
            href="https://vancouverballoons.com/pages/professional-services"
            className="site-nav__link site-nav__child-link site-nav__child-link--parent"
          >
            <span className="site-nav__label" style={{ fontWeight: 700 }}>
              PROFESSIONAL SERVICES
            </span>
          </a>

          <ul className={styles.unorderedList}>
            <li>
              <a
                href="https://vancouverballoons.com/collections/balloon-arch"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Balloon Arch</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/balloon-centerpiece"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Balloon Centerpiece</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/balloon-columns"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Balloon Columns</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/collections/balloon-drop"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Balloon Drop</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/products/custom-balloon-sculptures"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Balloon Sculptures</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/products/special-events"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Balloon Parties &amp; Events</span>
              </a>
            </li>
          </ul>
        </li>

        <li className="site-nav__childlist-item">
          <a
            href="https://vancouverballoons.com/pages/local-collaborations"
            className="site-nav__link site-nav__child-link site-nav__child-link--parent"
          >
            <span className="site-nav__label" style={{ fontWeight: 700 }}>
              LOCAL COLLABORATIONS
            </span>
          </a>

          <ul className={styles.unorderedList}>
            <li>
              <a
                href="https://vancouverballoons.com/pages/local-collaborations"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Shop All Collaborations</span>
              </a>
            </li>

            <li>
              <a
                href="https://vancouverballoons.com/pages/contact-us"
                className="site-nav__link site-nav__child-link"
              >
                <span className="site-nav__label">Collaborate With Us</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default ShopExpansion;
