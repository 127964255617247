import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import styles from "../assets/css/StageTracker.module.scss";

interface CircleProps {
  isActive: boolean;
}

function Circle({ isActive }: CircleProps) {
  return (
    <div
      className={styles.dot}
      style={{ backgroundColor: isActive ? "#caa352" : "#bbb" }}
    />
  );
}

interface StageTrackerProps {
  steps: string[];
  setCurrentStage: React.Dispatch<React.SetStateAction<number>>;
  currentStage: number;
}

function StageTracker({ steps, currentStage, setCurrentStage }: StageTrackerProps) {
  const { Step } = Steps;
  const [current, setCurrent] = useState(currentStage);

  useEffect(() => {
    setCurrent(currentStage);
  }, [currentStage]);

  const onChange = (index: number) => {
    setCurrent(index);
  };

  return (
    <Steps
      direction="vertical"
      style={{ height: "100%" }}
      current={current}
      onChange={onChange}
    >
      {steps.map((step, index) => (
        <Step
          key={step}
          icon={<Circle isActive={index <= current} />}
          onClick={() => {
            setCurrentStage(index);
          }}
          title={
            <div
              className={styles.step}
              style={{ color: index <= current ? "#caa352" : "#bbb" }}
            >
              {step}
            </div>
          }
        />
      ))}
    </Steps>
  );
}

export default StageTracker;
