import React, { useState } from "react";
import BalloonTypeSection from "../components/BalloonTypeSection";
import styles from "../assets/css/BalloonTypeSelection.module.scss";
import { Product, ProductType, Variant } from "../models/Product";

interface TopBalloonSelectionProps {
  products: Product[];
  onChooseBalloon: (item: Product | Variant) => void;
}

const productTypes: ProductType[] = [
  "Megaloons Balloons",
  "Bubble Balloons",
  "Mylar Foil Balloons",
  "Latex Balloons",
  "Supershape Balloons",
];

function TopBalloonSelection({
  products,
  onChooseBalloon,
}: TopBalloonSelectionProps) {
  // determines which section is opened
  const [sectionIndex, setSectionIndex] = useState<number | null>(null);

  const onSectionClick = (index: number) => {
    if (index === sectionIndex) {
      setSectionIndex(null);
    } else {
      setSectionIndex(index);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Balloon Bouquet</div>
      <div className={styles.sectionTitle}>Top Balloon Options</div>
      {productTypes.map((type, index) => (
        <BalloonTypeSection
          key={type}
          onSectionClick={() => {
            onSectionClick(index);
          }}
          typeName={type}
          selected={sectionIndex === index}
          products={products}
          onChooseBalloon={onChooseBalloon}
        />
      ))}
    </div>
  );
}

export default TopBalloonSelection;
