import React from "react";
import styles from "../assets/css/TotalPrice.module.scss";

interface TotalPriceProps {
  totalPrice: string;
}

function TotalPrice({ totalPrice }: TotalPriceProps) {
  return (
    <div className={styles.price}>Total: {`$${totalPrice}`} (before taxes)</div>
  );
}

export default TotalPrice;
