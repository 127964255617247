/* eslint-disable no-restricted-syntax */
import { Product, Variant } from "../models/Product";
import { Structure } from "../models/Structure";

/* eslint-disable no-await-in-loop */
async function storeFront(query: string, variables = {}) {
  const options = {
    method: "POST",
    headers: {
      "X-Shopify-Storefront-Access-Token": "51be8ee81ed48473664bf0a818b902fe",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query, variables }),
  };

  try {
    const response = await fetch(
      "https://vanballoons.myshopify.com/api/2022-04/graphql.json",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error fetching products");
  }
}

async function getProductsAfterCurosor(cursor?: string) {
  const productsQuery = cursor
    ? `
    query Products {
      products(
        first: 250
        after: "${cursor}"
      ) {
        edges {
          cursor
          node {
            id
            title
            productType
            tags
            totalInventory
            variants(first: 99) {
              edges {
                node {
                  id
                  title
                  availableForSale
                  currentlyNotInStock
                  priceV2 {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    transformedSrc
                  }
                }
              }
            }
            priceRange {
              minVariantPrice {
                amount
              }
            }
            images(first: 1) {
              edges {
                node {
                  transformedSrc
                  altText
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `
    : `
  query Products {
      products(
        first: 250
      ) {
        edges {
          cursor
          node {
            id
            title
            productType
            tags
            totalInventory
            variants(first: 99) {
              edges {
                node {
                  id
                  title
                  availableForSale
                  currentlyNotInStock
                  priceV2 {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    transformedSrc
                  }
                }
              }
            }
            priceRange {
              minVariantPrice {
                amount
              }
            }
            images(first: 1) {
              edges {
                node {
                  transformedSrc
                  altText
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `;
  const {
    data: {
      products: {
        edges,
        pageInfo: { hasNextPage },
      },
    },
  } = await storeFront(productsQuery);
  return { edges, hasNextPage };
}

export async function getAllProducts(): Promise<{ node: Product }[]> {
  const products = [];
  const { edges, hasNextPage } = await getProductsAfterCurosor();
  let hasNext = hasNextPage;
  let { cursor } = edges[edges.length - 1];
  products.push(...edges);

  while (hasNext) {
    const { edges: currEdges, hasNextPage: currHasNextPage } =
      await getProductsAfterCurosor(cursor);
    hasNext = currHasNextPage;
    cursor = currEdges[currEdges.length - 1].cursor;
    products.push(...currEdges);
  }

  return products;
}

// Source: https://shopify.dev/api/examples/checkout
export async function checkout(
  items: [Product | Variant, number][],
  strcuture: Structure,
  skeleton: (Product | Variant | null)[][]
) {
  const lineItems: { variantId: string; quantity: number }[] = [];
  const serviceFeeTenOrMore = "gid://shopify/ProductVariant/42975796887722";
  const serviceFeeLessThanTen = "gid://shopify/ProductVariant/42975791087786";
  let numberOfBalloons = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const [item, quantity] of items) {
    numberOfBalloons += quantity;
    lineItems.push({
      variantId: "images" in item ? item.variants.edges[0].node.id : item.node.id,
      quantity,
    });
  }
  // add service fee depending on total number of balloons
  lineItems.push({
    variantId: numberOfBalloons >= 10 ? serviceFeeTenOrMore : serviceFeeLessThanTen,
    quantity: 1,
  });

  let note: string = strcuture;
  for (const row of skeleton) {
    for (const balloon of row) {
      if (balloon) {
        note = note.concat(
          "images" in balloon
            ? ` ${balloon.images.edges[0].node.transformedSrc}`
            : ` ${balloon.node.image.transformedSrc}`
        );
      }
    }
  }

  const checkoutMutation = `
    mutation {
      checkoutCreate(input: {
        lineItems: ${JSON.stringify(lineItems).replace(/"(\w+)"\s*:/g, "$1:")}
        note: "${note}"
      }) {
        checkout {
          webUrl
        }
      }
    }
  `;

  const response = await storeFront(checkoutMutation);
  return response.data.checkoutCreate.checkout.webUrl;
}
