import React, { useEffect, useState } from "react";
import { Input, Button } from "antd";
import { Structure } from "../models/Structure";
import styles from "../assets/css/ImageBuilder.module.scss";

interface SkeletonRowProps {
  structure: Structure;
  row: string[];
  level: number;
}

function SkeletonRow({ structure, row, level }: SkeletonRowProps) {
  const getTransformDegree = (index: number) => {
    let res = "";
    if (level === 0) {
      return structure === "cascading" ? "rotate(20deg)" : "none";
    }
    switch (structure) {
      case "standard":
        res = index === 0 ? "rotate(-20deg)" : "rotate(20deg)";
        break;
      case "trio":
        if (index === 1) {
          res = "none";
        } else {
          res = index === 0 ? "rotate(-30deg)" : "rotate(30deg)";
        }
        break;
      case "cascading":
        res = level % 2 === 1 ? "rotate(-40deg)" : "rotate(40deg)";
        break;
      default:
        break;
    }

    return res;
  };

  return (
    <div className={styles.row} style={{ marginTop: 12 }}>
      {row.map((imageURL, index) => (
        <img
          className={level === 0 && index === 0 ? styles.topItem : styles.item}
          style={{
            "transform": getTransformDegree(index),
            "zIndex": structure === "trio" && index === 1 ? 3 : 0,
          }}
          key={`${imageURL}-${index}`}
          src={imageURL}
        />
      ))}
    </div>
  );
}

export default function ImageBuilder() {
  const [note, setNote] = useState("");
  const [structure, setStructure] = useState<Structure | "">("");
  const [rows, setRows] = useState<string[][]>([]);

  const rowNumberMap: Map<Structure, number> = new Map([
    ["trio", 3],
    ["standard", 2],
    ["cascading", 1],
  ]);

  useEffect(() => {
    if (note) {
      const noteArray = note.split(" ");
      setStructure(noteArray.shift() as Structure);

      const tempRows: string[][] = [];
      tempRows.push([noteArray.shift() as string]);
      let tempRow: string[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const url of noteArray) {
        tempRow.push(url);
        if (tempRow.length === rowNumberMap.get(structure as Structure)) {
          tempRows.push(tempRow);
          tempRow = [];
        }
      }
      setRows(tempRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, structure]);

  return (
    <div>
      <div className={styles.skeleton}>
        <Input.Group compact>
          <Input
            style={{ width: "calc(100% - 200px)" }}
            value={note}
            onChange={e => setNote(e.target.value)}
            allowClear
          />
          <Button
            onClick={async () => {
              const copiedNote = await navigator.clipboard.readText();
              setNote(copiedNote);
            }}
            type="primary"
          >
            Paste
          </Button>
        </Input.Group>
        {note && structure ? (
          rows.map((row, index) => (
            <SkeletonRow
              structure={structure}
              row={row}
              level={index}
              key={`${index}-${row}-skeleton`}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
