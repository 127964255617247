import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { getAllProducts } from "./utils/apiClients";
import BouquetBuilder from "./pages/BouquetBuilder";
import BuildingProcess from "./pages/BuildingProcess";
import { Product } from "./models/Product";
import ImageBuilder from "./components/ImageBuilder";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);

  const initiateProducts = async () => {
    const res = await getAllProducts();
    const allProducts = res.map(r => r.node);
    setProducts(allProducts);
    setIsLoading(false);
  };

  useEffect(() => {
    initiateProducts();
  }, []);

  return (
    <BrowserRouter>
      <Route exact path="/" component={() => <BouquetBuilder />} />
      <Route
        exact
        path="/bouquet-builder"
        component={() => (
          <BuildingProcess isLoading={isLoading} products={products} />
        )}
      />
      <Route exact path="/bouquet-converter" component={() => <ImageBuilder />} />
    </BrowserRouter>
  );
}
