/* eslint-disable no-shadow */
import React, { useState } from "react";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import VariantModal from "./VariantModal";
import styles from "../assets/css/BalloonTypeSection.module.scss";
import { Product, Variant } from "../models/Product";

// In the second stage of building process
interface BalloonTypeSectionProps {
  typeName: string;
  onSectionClick: () => void;
  selected: boolean;
  products: Product[];
  onChooseBalloon: (item: Product | Variant) => void;
}

function BalloonTypeSection({
  typeName,
  onSectionClick,
  selected,
  products,
  onChooseBalloon,
}: BalloonTypeSectionProps) {
  // this trigers modal rendering when a product has variants
  const [proudctSelected, setProudctSelected] = useState<Product | undefined>(
    undefined
  );

  // For a product with no variant, show the price and the size
  // For a product with variants, show the price range and size options
  const getToolTipText = (product: Product) => {
    if (product.variants.edges.length === 1) {
      const price = product.variants.edges[0].node.priceV2.amount;
      const size = product.tags.filter(tag => tag.includes("''"))[0];
      return `Price: $${price}, Size: ${size || "N/A"}`;
    }
    const prices = product.variants.edges.map(variant =>
      parseInt(variant.node.priceV2.amount, 10)
    );
    const sizeTags = product.tags.filter(tag => tag.includes("''"));
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    if (minPrice !== maxPrice) {
      return `Price starting from $${minPrice} to $${maxPrice} with size choices of: ${
        sizeTags.length ? sizeTags : "N/A"
      }`;
    }
    return `Price: $${minPrice} with size choices of: ${
      sizeTags.length ? sizeTags : "N/A"
    }`;
  };

  const onCancelProduct = () => {
    setProudctSelected(undefined);
  };

  const onProductClick = (p: Product) => {
    if (p.variants.edges.length === 1) {
      onChooseBalloon(p);
    } else {
      setProudctSelected(p);
    }
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <VariantModal
        product={proudctSelected}
        onCancel={onCancelProduct}
        onChooseBalloon={onChooseBalloon}
      />
      <div onClick={onSectionClick} className={styles.balloonTypeSection}>
        {typeName}
        {selected ? (
          <DownOutlined className={styles.arrow} />
        ) : (
          <RightOutlined className={styles.arrow} />
        )}
      </div>
      {selected && (
        <div className={styles.imagesWrapper}>
          {products
            .filter(p => p.productType === typeName)
            .map(p => (
              <Tooltip key={p.id} title={getToolTipText(p)}>
                <img
                  onClick={() => {
                    onProductClick(p);
                  }}
                  src={p.images.edges[0].node.transformedSrc}
                />
              </Tooltip>
            ))}
        </div>
      )}
    </div>
  );
}

export default BalloonTypeSection;
