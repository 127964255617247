import React from "react";
import { Dropdown } from "antd";
import { SearchOutlined, ShoppingOutlined, DownOutlined } from "@ant-design/icons";
import ShopExpansion from "./ShopExpansion";
import AboutUsExpansion from "./AboutUsExpansion";
import icon from "../assets/images/vbb_icon.png";
import styles from "../assets/css/BouquetHeader.module.scss";

function BouquetHeader() {
  return (
    <div className={styles.header}>
      <span className={styles.left}>
        <a href="https://vanballoons.myshopify.com/">
          <img src={icon} alt="Vancouver Balloons" />
        </a>
      </span>
      <ul className={styles.middle}>
        <Dropdown
          overlay={<ShopExpansion />}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <li className={styles.item}>
            <span className={styles.text}>SHOP</span>
            <DownOutlined className={styles.icon} />
          </li>
        </Dropdown>
        <li className={styles.item}>
          <a
            href="https://vancouverballoons.com/collections/helium-tank"
            style={{ color: "black" }}
          >
            <span className={styles.text}>HELIUM TANK RENTALS</span>
          </a>
        </li>
        <li className={styles.item}>
          <a
            href="https://vancouverballoons.com/pages/bouquet-builder"
            style={{ color: "black" }}
          >
            <span className={styles.text}>BOUQUET BUILDER</span>
          </a>
        </li>
        <li className={styles.item}>
          <a
            href="https://vancouverballoons.com/pages/gallery-page"
            style={{ color: "black" }}
          >
            <span className={styles.text}>GALLERY</span>
          </a>
        </li>
        <Dropdown
          overlay={<AboutUsExpansion />}
          placement="bottomRight"
          trigger={["click"]}
        >
          <li className={styles.item}>
            <span className={styles.text}>ABOUT US</span>
            <DownOutlined className={styles.icon} />
          </li>
        </Dropdown>
        <li className={styles.item}>
          <a
            href="https://vancouverballoons.com/pages/contact-us"
            style={{ color: "black" }}
          >
            <span className={styles.text}>CONTACT US</span>
          </a>
        </li>
      </ul>
      <span className={styles.right}>
        <a href="https://vancouverballoons.com/cart" style={{ color: "black" }}>
          <ShoppingOutlined className={styles.icon} />
        </a>
        <SearchOutlined className={styles.icon} style={{ marginRight: 10 }} />
      </span>
    </div>
  );
}

export default BouquetHeader;
