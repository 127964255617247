import React from "react";
import styles from "../assets/css/AboutUsExpansion.module.scss";

function AboutUsExpansion() {
  return (
    <div
      className="site-nav__dropdown critical-hidden site-nav__dropdown--left"
      id="SiteNavLabel-about-us"
    >
      <ul className={styles.wrapper}>
        <li>
          <a
            href="https://vancouverballoons.com/pages/about-us"
            className="site-nav__link site-nav__child-link"
          >
            <span className="site-nav__label">About Us</span>
          </a>
        </li>

        <li>
          <a
            href="https://vancouverballoons.com/pages/professional-work"
            className="site-nav__link site-nav__child-link"
          >
            <span className="site-nav__label">
              Professional Entertainment Services
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://vancouverballoons.com/blogs/news"
            className="site-nav__link site-nav__child-link site-nav__link--last"
          >
            <span className="site-nav__label">News Room</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default AboutUsExpansion;
